import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  install(Vue) {
    const { VUE_APP_LX_API_URL, VUE_APP_LX_API_VERSION } = process.env;
    const api = axios.create({
      baseURL: `${VUE_APP_LX_API_URL}${VUE_APP_LX_API_VERSION}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    api.interceptors.request.use(
      async (config) => {
        const user = firebase.auth().currentUser;
        if (!user) {
          // TODO Handle case where we are not yet authenticated!
          return config;
        }
        const token = await user.getIdToken();
        if (token) {
          config.headers.common["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // api.interceptors.response.use(
    //   response => {
    //     if (response.status === 200 || response.status === 201) {
    //       return Promise.resolve(response);
    //     } else {
    //       return Promise.reject(response);
    //     }
    //   },
    //   error => {
    //     if (error.response.status) {
    //       switch (error.response.status) {
    //         case 400:
    //           //do something
    //           break;
    //
    //         case 401:
    //           alert("session expired");
    //           break;
    //         case 403:
    //           Vue.router.replace({
    //             path: "/login",
    //             query: { redirect: Vue.router.currentRoute.fullPath }
    //           });
    //           break;
    //         case 404:
    //           alert("page not exist");
    //           break;
    //         case 502:
    //           setTimeout(() => {
    //             Vue.router.replace({
    //               path: "/login",
    //               query: {
    //                 redirect: Vue.router.currentRoute.fullPath
    //               }
    //             });
    //           }, 1000);
    //       }
    //       return Promise.reject(error.response);
    //     }
    //   }
    // );

    Vue.prototype.$httpApi = api;
  },
};
