<template>
  <div class="main-panel">
    <b-container>
      <b-row>
        <b-col sm="9" md="7" lg="5" class="mx-auto">
          <b-card class="card-signin my-5">
            <b-card-title class="text-center"
              >This is the ResetPassword page</b-card-title
            >
            <b-card-body>
              <b-button :to="{ name: 'login' }">
                Click here to go back</b-button
              >
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  metaInfo: {
    title: "Reset Password",
  },
};
</script>

<style lang="scss" scoped></style>
