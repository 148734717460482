<template>
  <div class="main-panel">
    <b-container>
      <b-row>
        <b-col sm="9" md="7" lg="5" class="mx-auto">
          <b-card class="card-signin my-5">
            <b-card-title class="text-center"> Sign In </b-card-title>
            <b-card-body>
              <!-- Form -->
              <b-form @submit="signInEmailAndPassword" class="form-signin">
                <!-- Email Input-->
                <b-form-group id="inputEmailGroup">
                  <label for="inputEmailGroup">Email address</label>
                  <b-form-input
                    id="inputEmail"
                    v-model="form.email"
                    type="email"
                    required
                    autofocus
                    placeholder="Email address"
                  ></b-form-input>
                </b-form-group>

                <!-- Password Input -->
                <b-form-group id="inputPasswordGroup">
                  <label for="inputPasswordGroup">Password</label>
                  <b-form-input
                    id="inputPassword"
                    v-model="form.password"
                    type="password"
                    required
                    autofocus
                    placeholder="Password"
                  ></b-form-input>
                </b-form-group>

                <!-- Remember Me Input -->
                <b-form-group id="inputRememberMeGroup">
                  <b-form-checkbox-group id="inputRememberMe">
                    <b-form-checkbox
                      class="mb-2 mr-sm-2 mb-sm-0"
                      :value="true"
                      :unchecked-value="false"
                      v-model="form.rememberMe"
                      >Remember password</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </b-form-group>

                <!-- Sign In Button -->
                <b-button
                  block
                  variant="primary"
                  class="text-uppercase"
                  type="submit"
                >
                  Sign in
                </b-button>

                <router-link
                  class="text-white-50 my-2"
                  :to="{ name: 'reset-password' }"
                >
                  Forgot your password?
                </router-link>

                <!-- FirebaseUI -->
                <section id="firebaseui-auth-container"></section>

                <!-- Splitter -->
                <hr class="divider my-4" />

                <!-- Register Button -->
                <b-button
                  block
                  variant="primary"
                  class="text-uppercase"
                  :to="{ name: 'register' }"
                >
                  Register
                </b-button>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import * as firebaseui from "firebaseui";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "login",
  metaInfo: {
    title: "LendiEX",
    titleTemplate: "",
  },
  data() {
    return {
      user: null,
      form: {
        email: "",
        password: "",
        rememberMe: false,
      },
    };
  },
  methods: {
    async signInEmailAndPassword(evt) {
      evt.preventDefault();
      const { email, password } = this.form;
      try {
        if (this.form.rememberMe) {
          await firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        } else {
          await firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION);
        }

        await firebase.auth().signInWithEmailAndPassword(email, password);
        this.$gtag.event("login");
        await this.$router.push({ name: "dashboard" });
      } catch (e) {
        console.error(e);
      }
    },
    async signInFirebase() {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await this.$router.push({ name: "dashboard" });
      return false;
    },
  },
  mounted() {
    this.uiInstance = firebaseui.auth.AuthUI.getInstance();
    if (!this.uiInstance) {
      this.uiInstance = new firebaseui.auth.AuthUI(firebase.auth());
    }

    this.uiInstance.start("#firebaseui-auth-container", {
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            prompt: "select_account",
          },
        },
      ],
      popupMode: true,
      siteName: "LendiEx",
      callbacks: {
        signInSuccessWithAuthResult: this.signInFirebase,
      },
    });
  },
  beforeDestroy() {
    this.uiInstance.reset();
  },
};
</script>

<style lang="scss">
@import "~firebaseui/dist/firebaseui.css";

.card-signin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);

  .card-title {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1.5rem;
  }

  .card-body {
    padding: 2rem;

    .divider {
      background-color: white;
    }
  }
}

.form-signin {
  width: 100%;

  .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    transition: all 0.2s;
  }
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;

  input {
    height: auto;
    border-radius: 2rem;
  }

  input:not(:placeholder-shown) {
    padding-top: calc(
      var(--input-padding-y) + var(--input-padding-y) * (2 / 3)
    );
    padding-bottom: calc(var(--input-padding-y) / 3);
  }
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}
</style>
