<template>
  <b-navbar type="dark" variant="dark" sticky>
    <b-navbar-brand :to="{ name: 'dashboard' }">LendiEX</b-navbar-brand>
    <b-navbar-nav class="ml-auto">
      <!--   Profile Pic Dropdown   -->
      <b-nav-item-dropdown type="dark" right>
        <template slot="button-content">
          <div class="photo">
            <img :src="userPicUrl" />
          </div>
        </template>
        <b-dropdown-item :to="{ name: 'settings' }"> Settings </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="logout()"> Logout </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth";
import { getCurrentUser } from "@/plugins/firebase";

export default {
  data() {
    return {
      userPicUrl: "img/user-pic-url.png",
    };
  },
  async created() {
    await this.getUserPicUrl();
  },
  methods: {
    async getUserPicUrl() {
      const currentUser = await getCurrentUser();
      this.userPicUrl = (await currentUser.getIdTokenResult()).claims.picture;
    },
    async logout() {
      try {
        await firebase.auth().signOut();
      } finally {
        await this.$router.push({ name: "login" });
      }
    },
  },
};
</script>
<style></style>
