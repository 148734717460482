<template>
  <b-col lg="6">
    <b-card class="lxwallet-card">
      <div slot="header">
        <h2 class="card-title">
          <IconCrypto
            v-if="currency"
            v-bind:coinname="currency"
            color="color"
            format="svg"
          />
          {{ currency }} - {{ longPlatform }}
        </h2>
        <div class="action-buttons">
          <b-button v-b-modal="'edit-wallet-' + wallet.id">
            <font-awesome-icon icon="edit"></font-awesome-icon>
          </b-button>
          <b-button @click="refresh">
            <font-awesome-icon icon="sync"></font-awesome-icon>
          </b-button>
        </div>
      </div>

      <div slot="default" class="lxwallet-content">
        <div v-if="loading" class="d-flex justify-content-center mb-3">
          <b-spinner variant="primary"></b-spinner>
        </div>
        <template v-else>
          <div class="d-flex flex-row">
            <!--  Winnings (Left side) -->
            <div class="wallet-info flex-fill">
              <h4>Winnings</h4>
              <div>
                All time: <span>{{ win }} {{ currency }}</span>
              </div>
              <div>
                Last 24 hours: <span>{{ win24h }} {{ currency }}</span>
              </div>
              <div>
                Last 30 days: <span>{{ win30d }} {{ currency }}</span>
              </div>
            </div>

            <!-- Stats (Right side) -->
            <div class="wallet-info flex-fill">
              <h4>Stats</h4>
              <div>
                Status: <span>{{ longStatus }}</span>
              </div>
              <div>
                Max Duration: <span>{{ maxDuration }} days</span>
              </div>
              <div>
                Total: <span>{{ total }}</span>
              </div>
              <div>
                Rate: <span>{{ rate }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </b-card>

    <b-modal
      :id="'edit-wallet-' + wallet.id"
      :title="'Edit Wallet ' + currency + ' - ' + longPlatform"
      @show="showModal"
      @ok="updateConfig"
    >
      <b-form-group id="status" label="Status:" label-cols>
        <b-form-select
          v-model="editModal.status"
          :options="statusOptions"
        ></b-form-select>
      </b-form-group>

      <b-form-group id="duration" label="Duration:" label-cols>
        <b-form-select
          v-model="editModal.maxDuration"
          :options="maxDurationOptions"
        ></b-form-select>
      </b-form-group>
    </b-modal>
  </b-col>
</template>

<script>
const PLATFORM_MAP = {
  po: "Polonix",
  bf: "Bitfinex",
};

export default {
  name: "lx-wallet",
  props: {
    wallet: Object,
  },
  data() {
    return {
      currency: null,
      platform: null,
      win: 0,
      win24h: 0,
      win30d: 0,
      total: 0,
      rate: 0.0,
      maxDuration: null,
      status: null,
      loading: false,
      editModal: {
        status: null,
        maxDuration: null,
      },
      statusOptions: [
        { value: "UP", text: "Enabled" },
        { value: "DOWN", text: "Disabled" },
      ],
      maxDurationOptions: [
        { value: 2, text: "2 Days" },
        { value: 30, text: "30 Days" },
      ],
    };
  },
  mounted() {
    this.setNewData(this.wallet);
  },
  computed: {
    longPlatform() {
      return PLATFORM_MAP[this.platform];
    },
    longStatus() {
      return this.status === "UP" ? "Enabled" : "Disabled";
    },
  },
  methods: {
    setNewData(newData) {
      this.currency = newData.currency;
      this.platform = newData.platform;
      this.win = newData.win;
      this.win24h = newData.win_24h;
      this.win30d = newData.win_30d;
      this.total = newData.total;
      this.rate = newData.rate;
      this.maxDuration = newData.max_duration;
      this.editModal.maxDuration = this.maxDuration;
      this.status = newData.status || "DOWN";
      this.editModal.status = this.status;
    },
    async showModal() {
      this.$gtag.event("edit_wallet_open_modal");
      await this.refresh();
    },
    async refresh() {
      this.loading = true;
      try {
        const response = await this.$httpApi.get(`/lxwallet/${this.wallet.id}`);
        this.setNewData(response.data);
      } finally {
        this.loading = false;
      }
    },
    async updateConfig() {
      this.loading = true;
      try {
        const response = await this.$httpApi.put(
          `/lxwallet/${this.wallet.id}`,
          {
            status: this.editModal.status,
            max_duration: this.editModal.maxDuration,
          }
        );
        this.setNewData(response.data);
        this.$gtag.event("edit_wallet_update_config");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/sass/black-dashboard";
.lxwallet-card {
  .card-header {
    .action-buttons {
      position: absolute;
      margin: 4px;
      top: 0;
      right: 0;
    }
  }
  .card-body {
    color: $white;

    .wallet-info {
      font-weight: bolder;
      div span {
        font-weight: normal;
      }
    }
  }
}
</style>
