<template>
  <div>Settings Page</div>
</template>

<script>
export default {
  name: "settings",
  metaInfo: {
    title: "Settings",
  },
};
</script>
