import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "@/layout/DashboardLayout";
import Dashboard from "@/views/Dashboard";
import Login from "@/views/Login";
import ResetPassword from "@/views/ResetPassword";
import Register from "@/views/Register";
import Settings from "@/views/Settings";
import { getCurrentUser } from "@/plugins/firebase";

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: ResetPassword,
    },
    {
      path: "/register",
      name: "register",
      component: Register,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/dashboard",
      component: DashboardLayout,
      children: [
        {
          path: "",
          name: "dashboard",
          components: { default: Dashboard },
        },
        {
          path: "settings",
          name: "settings",
          components: { default: Settings },
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // Redirect to dashboard if we are already logged in
  if (to.name === "login") {
    const currentUser = await getCurrentUser();
    if (currentUser) {
      next({ name: "dashboard" });
    }
  }

  // Check if user can log in (only if logged in and registered)
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth) {
    const currentUser = await getCurrentUser();
    if (!currentUser) {
      next({ name: "login" });
    }

    if (!(await currentUser.getIdTokenResult()).claims.registered) {
      if (to.matched.some((record) => record.name === "register")) {
        next();
      }
      next({ name: "register" });
    }
  }

  next();
});

// router.beforeEach((to, from, next) => {
//   // This goes through the matched routes from last to first, finding the closest route with a title.
//   // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
//   const nearestWithTitle = to.matched
//     .slice()
//     .reverse()
//     .find((r) => r.meta && r.meta.title);
//
//   // Find the nearest route element with meta tags.
//   const nearestWithMeta = to.matched
//     .slice()
//     .reverse()
//     .find((r) => r.meta && r.meta.metaTags);
//   const previousNearestWithMeta = from.matched
//     .slice()
//     .reverse()
//     .find((r) => r.meta && r.meta.metaTags);
//
//   // If a route with a title was found, set the document (page) title to that value.
//   const PREFIX = "LendiEX - ";
//   if (nearestWithTitle) {
//     document.title = PREFIX + nearestWithTitle.meta.title;
//   } else {
//     document.title = PREFIX + previousNearestWithMeta.meta.title;
//   }
//
//   // Remove any stale meta tags from the document using the key attribute we set below.
//   Array.from(
//     document.querySelectorAll("[data-vue-router-controlled]")
//   ).map((el) => el.parentNode.removeChild(el));
//
//   // Skip rendering meta tags if there are none.
//   if (!nearestWithMeta) return next();
//
//   // Turn the meta tag definitions into actual elements in the head.
//   nearestWithMeta.meta.metaTags
//     .map((tagDef) => {
//       const tag = document.createElement("meta");
//
//       Object.keys(tagDef).forEach((key) => {
//         tag.setAttribute(key, tagDef[key]);
//       });
//
//       // We use this to track which meta tags we create, so we don't interfere with other ones.
//       tag.setAttribute("data-vue-router-controlled", "");
//
//       return tag;
//     })
//     // Add the meta tags to the document head.
//     .forEach((tag) => document.head.appendChild(tag));
//
//   next();
// });
