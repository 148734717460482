// DISABLE SIDEBAR import SideBar from "@/components/SidebarPlugin";
import RTLPlugin from "./RTLPlugin";

//css assets
import "@/assets/sass/black-dashboard.scss";
import "@/assets/css/nucleo-icons.css";

export default {
  install(Vue) {
    Vue.use(RTLPlugin);
  },
};
