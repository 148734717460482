export default {
  colors: {
    default: "#344675",
    primary: "#42b883",
    info: "#1d8cf8",
    danger: "#fd5d93",
    teal: "#00d6b4",
    primaryGradient: [
      "rgba(76, 211, 150, 0.1)",
      "rgba(53, 183, 125, 0)",
      "rgba(119,52,169,0)",
    ],
  },
};

export const firebaseConfig = {
  apiKey: "AIzaSyBrY2pbmaLmaMfoHN_RoERBCLtME-dxFM4",
  authDomain: "lendiex-88717.firebaseapp.com",
  databaseURL: "https://lendiex-88717.firebaseio.com",
  projectId: "lendiex-88717",
  storageBucket: "lendiex-88717.appspot.com",
  messagingSenderId: "187373142494",
  appId: "1:187373142494:web:2c07b04c5cc6b763bf1d4f",
  measurementId: "G-4PZPT9Z1Q7",
};
