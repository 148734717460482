<template>
  <b-container fluid="md" class="dashboard">
    <!--  Grid of wallets  -->
    <div v-if="loading" class="d-flex justify-content-center mb-3">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <b-row v-else-if="wallets">
      <lx-wallet
        v-for="wallet in wallets"
        :key="wallet.id"
        v-bind:wallet="wallet"
      ></lx-wallet>
    </b-row>
    <div v-else>
      <b-card>
        <b-card-header>
          <h2>No Wallets could be found</h2>
        </b-card-header>
        <b-card-body>
          Either your access keys for your platforms are not properly configured
          or we haven't been able to find any wallet in your platform.
        </b-card-body>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import LxWallet from "@/components/LxWallet";

export default {
  name: "dashboard",
  metaInfo: {
    title: "Dashboard",
  },
  components: {
    LxWallet,
  },
  data() {
    return {
      wallets: [],
      loading: true,
    };
  },
  async created() {
    await this.refreshWallets();
  },
  methods: {
    async refreshWallets() {
      try {
        this.wallets = (await this.$httpApi.get("/lxwallet")).data;
        this.loading = false;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
<style lang="scss">
.dashboard {
  padding-top: 30px;
}
</style>
