import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "@/plugins/bootstrap-vue";
import "@/plugins/fontawesome";
import "@/plugins/firebase";
import App from "./App.vue";
import "@/registerServiceWorker";
import { router } from "./router";
import { store } from "@/store";
import i18n from "@/i18n";
import BlackDashboard from "@/plugins/blackDashboard";
import IconCrypto from "vue-cryptocurrency-icons";
import Axios from "@/plugins/axios";
import VueMeta from "vue-meta";
import VueGtag from "vue-gtag";

Vue.use(BlackDashboard);
Vue.use(IconCrypto);
Vue.use(Axios);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

Vue.use(
  VueGtag,
  {
    config: { id: "G-B9QZ52Q07J" },
    appName: "LendiEx",
    pageTrackerScreenviewEnabled: true,
  },
  router
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
