import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import { firebaseConfig } from "@/config";

firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

// function isPWA() {
//   return window && window.matchMedia("(display-mode: standalone)").matches;
// }
